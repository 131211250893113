import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import EstateVisualContainer from "@/components/estates/visual/estateVisualContainer"

import wiz1a from "@/images/z-19/UJECIE1v3.jpg"
import wiz2a from "@/images/z-19/UJECIE4v3.jpg"
import wiz3 from "@/images/wiczlino-2/comfort_parter.jpg"
import wiz4 from "@/images/wiczlino-2/comfort_pietro.jpg"
import wiz5b from "@/images/wiczlino-2/z6g.jpg"
import wiz6a from "@/images/wiczlino-2/wiz3a.jpg"
import wiz7 from "@/images/wiczlino-2/premium_parter.jpg"
import wiz8 from "@/images/wiczlino-2/premium_pietro.jpg"
import wiz9 from "@/images/z-9/Lazienka2_V03.jpg"
import wiz10 from "@/images/z-9/Lazienka3_V03.jpg"

const Visual = ({ intl }) => {
  const sections = [
    {
      title: intl.formatMessage({
        id: "offer.wiczlinotwo.visual.title.comfort",
      }),
      items: [
        {
          text: intl.formatMessage({
            id: "offer.wiczlinotwo.visual.first.item.content",
          }),
          img: wiz1a,
        },
        {
          text: intl.formatMessage({
            id: "offer.wiczlinotwo.visual.second.item.content",
          }),
          img: wiz2a,
        },
      ],
    },
    {
      title: intl.formatMessage({
        id: "offer.wiczlinotwo.visual.third.item.title",
      }),
      items: [
        {
          text: intl.formatMessage({
            id: "offer.wiczlinotwo.visual.third.item.content",
          }),
          img: wiz3,
        },
        {
          text: intl.formatMessage({
            id: "offer.wiczlinotwo.visual.fourth.item.content",
          }),
          img: wiz4,
        },
      ],
    },
    {
      title: intl.formatMessage({
        id: "offer.wiczlinotwo.visual.title.premium",
      }),
      items: [
        {
          text: intl.formatMessage({
            id: "offer.wiczlinotwo.visual.fifth.item.content",
          }),
          img: wiz5b,
        },
        {
          text: intl.formatMessage({
            id: "offer.wiczlinotwo.visual.sixth.item.content",
          }),
          img: wiz6a,
        },
      ],
    },
    {
      title: intl.formatMessage({
        id: "offer.wiczlinotwo.visual.third.item.title",
      }),
      items: [
        {
          text: intl.formatMessage({
            id: "offer.wiczlinotwo.visual.seventh.item.content",
          }),
          img: wiz7,
        },
        {
          text: intl.formatMessage({
            id: "offer.wiczlinotwo.visual.eighth.item.content",
          }),
          img: wiz8,
        },
      ],
    },
    // TODO move to translations
    {
      title: "Zmiany aranżacyjne i wykończenia pod klucz",
      items: [
        {
          text: `Wiemy, że wykończenie domu jest nie lada wyzwaniem, dlatego bazując na wieloletnim doświadczeniu naszych architektów i współpracy ze sprawdzonymi wykonawcami stworzyliśmy dział Zmian Aranżacyjnych i Wykończeń Pod Klucz.<br><br>Jeśli potrzebujesz wprowadzić zmiany w układzie swojego domu tak aby spełniało ono Państwa potrzeby, zaraz po zakupie lokalu, wspólnie z naszym Architektem możesz dokonać niezbędnych poprawek.`,
          img: wiz9,
        },
        {
          text: `Możesz również skorzystać z pakietów wykończeniowych oszczędzając swój czas i pieniądze. To doskonałe rozwiązanie dzięki któremu zamieszkasz w pięknych i funkcjonalnych wnętrzach. Współpracując z naszym architektem stworzysz przestrzeń spełniającą Twoje oczekiwania. `,
          img: wiz10,
          style: { marginTop: "-42%" },
        },
      ],
    },
  ]

  return <EstateVisualContainer sections={sections} />
}
export default injectIntl(Visual)
