import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { MobileButton } from "@/components/shared/button/button"
const EstateVisualDownload = () => {
  return (
    <Container>
      <Row>
        <Col style={{'maxWidth': '600px', 'margin': '-30px auto 30px' }}>
          <a target="_blank" href="/assets/files/zmiany_aranzacyjne.pdf">
            <MobileButton
              innerText="Poznaj szczegóły dotyczące zmian aranżacyjnych"
              bg="#94C835"
              color="white"
              border="#94C835"
              arrow={false}
            />
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default EstateVisualDownload
