import styled from "styled-components"
import home from "../../../images/home21.png"
import vector from "../../../images/vector-map-icon.png"
import vectorPic from "../../../images/vector-pic.png"
import plan from "../../../images/wiczlino-2/wiz5.jpg"

export const HeaderContainer = styled.div`
  .content-wrapper {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    padding: 0;
  }
  .content-status {
    padding: 0;
  }
  @media only screen and (min-width: 768px) {
    margin-bottom: 45px;
  }
  /* @media only screen and (min-width: 1440px) {
    margin: 0 90px 0;
  } */
  .desktop-info {
    margin: 45px 0 0;
    padding: 35px 0 0;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #94c835;
    align-items: center;
    justify-content: space-around;
    gap: 17px;
    div {
      //margin-right: 35px;
      text-align: center;
      font-size: 36px;
      span {
        font-size: 24px;
        @media only screen and (max-width: 991px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 20px;
        }
      }
      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 1200px) {
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 767px) {
      // display: none;
      flex-direction: column;
    }
  }
  .vector-pic {
    background-image: url(${vectorPic});
    background-repeat: no-repeat;
    background-position: center bottom;
    padding: 0 30px;
    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }
  .header-nav {
    padding: 0;
  }
`

export const PlanVisual = styled.div`
  text-align: center;

  .plan-wiz {
    height: 560px;
    background-image: url(${plan});
    background-size: cover;
    background-position: center;
  }
  img {
    max-width: 100%;
    margin: 0 auto;

    @media (min-width: 1300px) and (max-height: 700px) {
      max-height: 120vh;
    }
  }
`

export const DescriptionStyled = styled.div`
  @media only screen and (min-width: 1440px) {
    margin: 0 90px 0;
  }

  .title {
    padding: 0;
    margin: 100px 0 40px;
    h1 {
      font-size: 37px;
      margin: 0;
    }
  }
  .no-padding {
    /* padding: 0; */
    margin: auto;
  }

  .content {
    padding: 0 30px;
    font-size: 20px;
    font-weight: 200;
    margin-bottom: 20px;

    strong {
      font-weight: 500;
    }
    p:nth-of-type(4) {
    }
    .paragraph {
      padding: 0;
    }
    .paragraph:nth-of-type(2) p {
      font-weight: 500;
    }
    .paragraph:nth-of-type(5) {
      margin-bottom: 40px;
    }
    .icons-section {
      @media only screen and (min-width: 768px) {
        margin-bottom: 100px;
      }
      > div {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        h4 {
          font-size: 20px;
          font-weight: 500;
          margin: 10px 6px;
        }
        div {
          display: inline-block;
          min-width: 48px;
          height: 48px;
          margin: 10px 16px 10px 5px;
          border: 2px solid #94c835;
          border-radius: 8px;
          position: relative;
          ::after {
            position: absolute;
            height: 40px;
            width: 15px;
            background-color: transparent;
            content: "";
            left: 60%;
            top: 10px;
            transform: translate(-50%, -50%) rotate(40deg);
            border-right: 2px solid #94c835;
            border-bottom: 2px solid #94c835;
          }
          ::before {
            position: absolute;
            border-radius: 50%;
            top: -8px;
            right: -16px;
            transform: translate(-50%);
            height: 16px;
            width: 16px;
            content: "";
            background-color: white;
          }
        }
      }
    }
  }
  .vector-image-container {
    height: 100%;
  }
  .vector-image {
    margin: 0;
    height: 100%;
    div {
      padding: 0;
      width: 100%;
      right: -45px;
      min-height: 360px;
      margin: 30px 0 0;
      background-image: url(${home});
      background-repeat: no-repeat;
      background-position: right;
      @media only screen and (max-width: 1199px) {
        background-position: left center;
      }
    }
  }
`

export const LocalizationContainer = styled.div`
  .no-padding {
    margin: 0;
    padding: 0;
  }
  background-color: #f8f8f8;
  .localization-title {
    padding: 50px 30px 0px;
    h1 {
      margin: 0;
      font-size: 37px;
    }
  }
  .google-map-row {
    /* min-height: 375px; */
    position: relative;
    @media only screen and (min-width: 1200px) {
      min-height: 600px;
    }
    .loc-map {
      width: 100%;
      @media only screen and (max-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }
  .localization-content {
    padding: 40px 30px 35px;
    font-size: 20px;
    font-weight: 200;
    background-color: #f8f8f8;
    .places-title {
      margin: auto;
    }
    .places-offset {
      display: none;
      @media only screen and (min-width: 1200px) {
        display: flex;
      }
    }
    span {
      font-weight: 500;
    }
  }
  .places-info {
    span {
      font-size: 37px;
      font-weight: 500;
    }
    div {
      font-size: 20px;
      font-weight: 200;
    }
    div:nth-child(1) {
      padding: 40px 0 15px;
      border-bottom: solid 1px #94c835;
    }
    div:nth-child(2) {
      padding: 15px 0;
      border-bottom: solid 1px #94c835;
    }
    div:nth-child(3) {
      padding: 15px 0;
    }
  }
  .localization-vector {
    background-color: #f8f8f8;
    min-height: 160px;
    @media only screen and (min-width: 1200px) {
      display: none;
    }
    .vector-background {
      min-height: 160px;
      width: 100%;
      background-image: url(${vector});
    }
  }
`
export const EstatePlanContainer = styled.div`
  /* background-color: #92c836; */
  .no-padding {
    margin: 0;
    padding: 0;
  }
  .plan-title {
    padding: 60px 0 40px;
  }
  .plan-content {
    font-size: 20px;
    font-weight: 200;
    span {
      font-weight: 400;
    }
  }
  .plan-container {
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4em;
    @media only screen and (min-width: 1200px) {
      text-align: center;
    }
    .plan-img {
      width: 100%;
    }
  }
  .mobile-text {
    @media only screen and (min-width: 767px) {
      display: none;
    }
  }
  .desktop-text {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`

export const Image = styled.div`
  padding: 0;
  overflow: hidden;
  min-height: 100px;
  min-width: 100px;
  position: relative;
  display: flex;
  justify-content: space-around;
  background-image: url(${props => props.src});
  background-size: cover;
  transition: all linear 0.4s;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`
