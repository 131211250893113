import React from "react"
import { EstatePlanContainer } from "./wiczlinoStyle"
import { Col, Container, Row } from "react-bootstrap"
// import plan from '../../../images/wiczlino-2/mapa_www_etap2.gif'
import planImage from "@/images/z-20/2024-06-11-PZT_rys-v2-01.png"

const EstatePlan = ({ searchFunction, scrollDestination, intl }) => (
  <EstatePlanContainer className="plan">
    <Container>
      <Row>
        <Col className="plan-title no-padding" xs="12">
          <h1>Plan Osiedla</h1>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col className="no-padding" xs="12">
          <div className="plan-container">
            <img className="plan-img" src={planImage} alt="Plan OGW2 " />
          </div>
        </Col>
      </Row>
    </Container>
  </EstatePlanContainer>
)

export default EstatePlan
