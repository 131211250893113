// var janowo_tmp = {};

// const myImage = document.querySelector('img');
//
// let myHeaders = new Headers();
// myHeaders.append('Content-Type', 'image/jpeg');
//
// const myInit = {
//   method: 'GET',
//   headers: myHeaders,
//   mode: 'cors',
//   cache: 'default'
// };
//
// let myRequest = new Request('http://echo.jsontest.com/key2/value2/key/value');
//
// fetch(myRequest, myInit).then(response => response.json())
//     .then(response => {
//       janowo_tmp = response;
// });
//
// // fetch(myRequest, myInit).then(function(response) {
// //   console.log(response.json())
// //
// //
// // });
//
// export var janowo = [janowo_tmp];

export const janowo = [
  //  'SECTION': "GDYNIA JANOWO FLATS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"


  {
    '0': 'OJP/B08/P0/M01',
    '1': 'B8',
    '2': '01',
    '3': 'P0',
    '4': '40,57',
    '5': 'dwa',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P0-M01.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B08/P0/M02',
    '1': 'B8',
    '2': '02',
    '3': 'P0',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P0/M03',
    '1': 'B8',
    '2': '03',
    '3': 'P0',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P0/M04',
    '1': 'B8',
    '2': '04',
    '3': 'P0',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P0-M04.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P0/M05',
    '1': 'B8',
    '2': '05',
    '3': 'P0',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P0-M05.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },    {
    '0': 'OJP/B08/P1/M06',
    '1': 'B8',
    '2': '06',
    '3': 'P1',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P1-M06.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B08/P1/M07',
    '1': 'B8',
    '2': '07',
    '3': 'P1',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P1/M08',
    '1': 'B8',
    '2': '08',
    '3': 'P1',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P1-M8.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P1/M09',
    '1': 'B8',
    '2': '09',
    '3': 'P1',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P1-M09.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P1/M10',
    '1': 'B8',
    '2': '10',
    '3': 'P1',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P1-M10.pdf',
    '12': 'small4049',
    '13': '',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P2/M11',
    '1': 'B8',
    '2': '11',
    '3': 'P2',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P2-M11.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B08/P2/M12',
    '1': 'B8',
    '2': '12',
    '3': 'P2',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P2-M12.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P2/M13',
    '1': 'B8',
    '2': '13',
    '3': 'P2',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P2-M13.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P2/M14',
    '1': 'B8',
    '2': '14',
    '3': 'P2',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P2-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P2/M15',
    '1': 'B8',
    '2': '15',
    '3': 'P2',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P2-M15.pdf',
    '12': 'small4049',
    '13': '',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P3/M16',
    '1': 'B8',
    '2': '16',
    '3': 'P3',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P3-M16.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B08/P3/M17',
    '1': 'B8',
    '2': '17',
    '3': 'P3',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P3-M17.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P3/M18',
    '1': 'B8',
    '2': '18',
    '3': 'P3',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P3-M18.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P3/M19',
    '1': 'B8',
    '2': '19',
    '3': 'P3',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P3-M19.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B08/P3/M20',
    '1': 'B8',
    '2': '20',
    '3': 'P3',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B08-P3-M20.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P0/M01',
    '1': 'B9',
    '2': '01',
    '3': 'P0',
    '4': '45,83',
    '5': 'dwa',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P0-M01.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B09/P0/M02',
    '1': 'B9',
    '2': '02',
    '3': 'P0',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P0/M03',
    '1': 'B9',
    '2': '03',
    '3': 'P0',
    '4': '43,37',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P0/M04',
    '1': 'B9',
    '2': '04',
    '3': 'P0',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P0-M04.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P0/M05',
    '1': 'B9',
    '2': '05',
    '3': 'P0',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P0-M05.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },    {
    '0': 'OJP/B09/P1/M06',
    '1': 'B9',
    '2': '06',
    '3': 'P1',
    '4': '45,83',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P1-M06.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B09/P1/M07',
    '1': 'B9',
    '2': '07',
    '3': 'P1',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P1/M08',
    '1': 'B9',
    '2': '08',
    '3': 'P1',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P1-M08.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P1/M09',
    '1': 'B9',
    '2': '09',
    '3': 'P1',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P1-M09.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P1/M10',
    '1': 'B9',
    '2': '10',
    '3': 'P1',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P1-M10.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P2/M11',
    '1': 'B9',
    '2': '11',
    '3': 'P2',
    '4': '45,83',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P2-M11.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B09/P2/M12',
    '1': 'B9',
    '2': '12',
    '3': 'P2',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P2-M12.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P2/M13',
    '1': 'B9',
    '2': '13',
    '3': 'P2',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P2-M13.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P2/M14',
    '1': 'B9',
    '2': '14',
    '3': 'P2',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P2-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P2/M15',
    '1': 'B9',
    '2': '15',
    '3': 'P2',
    '4': '54,35',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P2-M15.pdf',
    '12': 'small4049',
    '13': '',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P3/M16',
    '1': 'B9',
    '2': '16',
    '3': 'P3',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P3-M16.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B09/P3/M17',
    '1': 'B9',
    '2': '17',
    '3': 'P3',
    '4': '72,27',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P3-M17.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P3/M18',
    '1': 'B9',
    '2': '18',
    '3': 'P3',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P3-M18.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },  {
    '0': 'OJP/B09/P3/M19',
    '1': 'B9',
    '2': '19',
    '3': 'P3',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2022',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B09-P3-M19.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },





  {
    '0': 'OJP/B10/P0/M01',
    '1': 'B10',
    '2': '01',
    '3': 'P0',
    '4': '45,83',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P0-M01.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P0/M02',
    '1': 'B10',
    '2': '02',
    '3': 'P0',
    '4': '48,30',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P0/M03',
    '1': 'B10',
    '2': '03',
    '3': 'P0',
    '4': '43,37',
    '5': 'dwa',
    '6': 'południe',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P0/M04',
    '1': 'B10',
    '2': '04',
    '3': 'P0',
    '4': '53,10',
    '5': 'trzy',
    '6': 'południe, wschód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P0-M04.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P0/M05',
    '1': 'B10',
    '2': '05',
    '3': 'P0',
    '4': '54,29',
    '5': 'trzy',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P0-M05.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P1/M06',
    '1': 'B10',
    '2': '06',
    '3': 'P1',
    '4': '45,83',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P1-M06.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P1/M07',
    '1': 'B10',
    '2': '07',
    '3': 'P1',
    '4': '48,29',
    '5': 'trzy',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P1/M08',
    '1': 'B10',
    '2': '08',
    '3': 'P1',
    '4': '43,37',
    '5': 'dwa',
    '6': 'południe',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P1-M08.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P1/M09',
    '1': 'B10',
    '2': '09',
    '3': 'P1',
    '4': '53,10',
    '5': 'trzy',
    '6': 'południe, wschód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P1-M09.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P1/M10',
    '1': 'B10',
    '2': '10',
    '3': 'P1',
    '4': '54,29',
    '5': 'trzy',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P1-M10.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P2/M11',
    '1': 'B10',
    '2': '11',
    '3': 'P2',
    '4': '45,83',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P2-M11.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P2/M12',
    '1': 'B10',
    '2': '12',
    '3': 'P2',
    '4': '48,29',
    '5': 'trzy',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P2-M12.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P2/M13',
    '1': 'B10',
    '2': '13',
    '3': 'P2',
    '4': '43,37',
    '5': 'dwa',
    '6': 'południe',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P2-M13.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P2/M14',
    '1': 'B10',
    '2': '14',
    '3': 'P2',
    '4': '53,10',
    '5': 'trzy',
    '6': 'południe, wschód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P2-M14.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P2/M15',
    '1': 'B10',
    '2': '15',
    '3': 'P2',
    '4': '54,35',
    '5': 'trzy',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P2-M15.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P3/M16',
    '1': 'B10',
    '2': '16',
    '3': 'P3',
    '4': '45,83',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P3-M16.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P3/M17',
    '1': 'B10',
    '2': '17',
    '3': 'P3',
    '4': '72,27',
    '5': 'cztery',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P3-M17.pdf',
    '12': 'large6075',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P3/M18',
    '1': 'B10',
    '2': '18',
    '3': 'P3',
    '4': '64,97',
    '5': 'cztery',
    '6': 'południe, wschód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P3-M18.pdf',
    '12': 'large6075',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B10/P3/M19',
    '1': 'B10',
    '2': '19',
    '3': 'P3',
    '4': '54,35',
    '5': 'trzy',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B10-P3-M19.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },


  {
    '0': 'OJP/B07/P0/M01',
    '1': 'B7',
    '2': '01',
    '3': 'P0',
    '4': '40,57',
    '5': 'dwa',
    '6': 'wschód, południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P0-M01.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P0/M02',
    '1': 'B7',
    '2': '02',
    '3': 'P0',
    '4': '48,34',
    '5': 'dwa',
    '6': 'północ, zachód, południe',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P0/M03',
    '1': 'B7',
    '2': '03',
    '3': 'P0',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P0/M04',
    '1': 'B7',
    '2': '04',
    '3': 'P0',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, północ, wschód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P0-M04.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P0/M05',
    '1': 'B7',
    '2': '05',
    '3': 'P0',
    '4': '47,34',
    '5': 'dwa',
    '6': 'północ, wschód, południe',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P0-M05.pdf',
    '12': 'small4049',
    '13': '',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P1/M06',
    '1': 'B7',
    '2': '06',
    '3': 'P1',
    '4': '57,57',
    '5': 'trzy',
    '6': 'wschód, południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P1-M06.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P1/M07',
    '1': 'B7',
    '2': '07',
    '3': 'P1',
    '4': '48,34',
    '5': 'dwa',
    '6': 'północ, zachód, południe',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P1/M08',
    '1': 'B7',
    '2': '08',
    '3': 'P1',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P1-M08.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P1/M09',
    '1': 'B7',
    '2': '09',
    '3': 'P1',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, północ, wschód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P1-M09.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P1/M10',
    '1': 'B7',
    '2': '10',
    '3': 'P1',
    '4': '47,34',
    '5': 'dwa',
    '6': 'północ, wschód, południe',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P1-M10.pdf',
    '12': 'small4049',
    '13': '',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P2/M11',
    '1': 'B7',
    '2': '11',
    '3': 'P2',
    '4': '57,57',
    '5': 'trzy',
    '6': 'wschód, południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P2-M11.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P2/M12',
    '1': 'B7',
    '2': '12',
    '3': 'P2',
    '4': '48,34',
    '5': 'dwa',
    '6': 'północ, zachód, południe',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P2-M12.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P2/M13',
    '1': 'B7',
    '2': '13',
    '3': 'P2',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P2-M13.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P2/M14',
    '1': 'B7',
    '2': '14',
    '3': 'P2',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, północ, wschód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P2-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P2/M15',
    '1': 'B7',
    '2': '15',
    '3': 'P2',
    '4': '47,34',
    '5': 'dwa',
    '6': 'północ, wschód, południe',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P2-M15.pdf',
    '12': 'small4049',
    '13': '',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P3/M16',
    '1': 'B7',
    '2': '16',
    '3': 'P3',
    '4': '57,57',
    '5': 'trzy',
    '6': 'wschód, południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P3-M16.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P3/M17',
    '1': 'B7',
    '2': '17',
    '3': 'P3',
    '4': '48,34',
    '5': 'dwa',
    '6': 'północ, zachód, południe',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P3-M17.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P3/M18',
    '1': 'B7',
    '2': '18',
    '3': 'P3',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P3-M18.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P3/M19',
    '1': 'B7',
    '2': '19',
    '3': 'P3',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, północ, wschód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P3-M19.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B07/P3/M20',
    '1': 'B7',
    '2': '20',
    '3': 'P3',
    '4': '47,34',
    '5': 'dwa',
    '6': 'północ, wschód, południe',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B07-P3-M20.pdf',
    '12': 'small4049',
    '13': '',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },


  {
    '0': 'OJP/B06/P0/M01',
    '1': 'B6',
    '2': '01',
    '3': 'P0',
    '4': '50,44',
    '5': 'trzy',
    '6': 'wschód, południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P0-M01.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P0/M02',
    '1': 'B6',
    '2': '02',
    '3': 'P0',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P0/M03',
    '1': 'B6',
    '2': '03',
    '3': 'P0',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P0/M04',
    '1': 'B6',
    '2': '04',
    '3': 'P0',
    '4': '50,50',
    '5': 'dwa',
    '6': 'wschód, północ, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P0-M04.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P1/M05',
    '1': 'B6',
    '2': '05',
    '3': 'P1',
    '4': '56,51',
    '5': 'trzy',
    '6': 'wschód, południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P1-M05.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P1/M06',
    '1': 'B6',
    '2': '06',
    '3': 'P1',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P1-M06.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P1/M07',
    '1': 'B6',
    '2': '07',
    '3': 'P1',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P1/M08',
    '1': 'B6',
    '2': '08',
    '3': 'P1',
    '4': '50,50',
    '5': 'dwa',
    '6': 'wschód, północ, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P1-M08.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P2/M09',
    '1': 'B6',
    '2': '09',
    '3': 'P2',
    '4': '56,51',
    '5': 'trzy',
    '6': 'wschód, południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P2-M09.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P2/M10',
    '1': 'B6',
    '2': '10',
    '3': 'P2',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P2-M10.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P2/M11',
    '1': 'B6',
    '2': '11',
    '3': 'P2',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P2-M11.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P2/M12',
    '1': 'B6',
    '2': '12',
    '3': 'P2',
    '4': '50,50',
    '5': 'dwa',
    '6': 'wschód, północ, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P2-M12.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P3/M13',
    '1': 'B6',
    '2': '13',
    '3': 'P3',
    '4': '56,51',
    '5': 'trzy',
    '6': 'wschód, południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P3-M13.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P3/M14',
    '1': 'B6',
    '2': '14',
    '3': 'P3',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P3-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P3/M15',
    '1': 'B6',
    '2': '15',
    '3': 'P3',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P3-M15.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B06/P3/M16',
    '1': 'B6',
    '2': '16',
    '3': 'P3',
    '4': '50,50',
    '5': 'dwa',
    '6': 'wschód, północ, zachód',
    '7': 'IV kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B06-P3-M16.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },


  {
    '0': 'OJP/B05/P0/M01',
    '1': 'B5',
    '2': '01',
    '3': 'P0',
    '4': '40,57',
    '5': 'dwa',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P0-M01.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P0/M02',
    '1': 'B5',
    '2': '02',
    '3': 'P0',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P0/M03',
    '1': 'B5',
    '2': '03',
    '3': 'P0',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P0/M04',
    '1': 'B5',
    '2': '04',
    '3': 'P0',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P0-M04.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P0/M05',
    '1': 'B5',
    '2': '05',
    '3': 'P0',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P0-M05.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P1/M06',
    '1': 'B5',
    '2': '06',
    '3': 'P1',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P1-M06.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P1/M07',
    '1': 'B5',
    '2': '07',
    '3': 'P1',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P1/M08',
    '1': 'B5',
    '2': '08',
    '3': 'P1',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P1-M08.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P1/M09',
    '1': 'B5',
    '2': '09',
    '3': 'P1',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P1-M09.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P1/M10',
    '1': 'B5',
    '2': '10',
    '3': 'P1',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P1-M10.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P2/M11',
    '1': 'B5',
    '2': '11',
    '3': 'P2',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P2-M11.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P2/M12',
    '1': 'B5',
    '2': '12',
    '3': 'P2',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P2-M12.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P2/M13',
    '1': 'B5',
    '2': '13',
    '3': 'P2',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P2-M13.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P2/M14',
    '1': 'B5',
    '2': '14',
    '3': 'P2',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P2-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P2/M15',
    '1': 'B5',
    '2': '15',
    '3': 'P2',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P2-M15.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P3/M16',
    '1': 'B5',
    '2': '16',
    '3': 'P3',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P3-M16.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P3/M17',
    '1': 'B5',
    '2': '17',
    '3': 'P3',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P3-M17.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P3/M18',
    '1': 'B5',
    '2': '18',
    '3': 'P3',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P3-M18.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B05/P3/M19',
    '1': 'B5',
    '2': '19',
    '3': 'P3',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P3-M19.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B5/P3/M20',
    '1': 'B5',
    '2': '20',
    '3': 'P3',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B05-P3-M20.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },


  {
    '0': 'OJP/B04/P0/M01',
    '1': 'B4',
    '2': '01',
    '3': 'P0',
    '4': '50,44',
    '5': 'trzy',
    '6': 'południe, zachód, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P0-M01.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P0/M02',
    '1': 'B4',
    '2': '02',
    '3': 'P0',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P0/M03',
    '1': 'B4',
    '2': '03',
    '3': 'P0',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P0/M04',
    '1': 'B4',
    '2': '04',
    '3': 'P0',
    '4': '50,50',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P0-M04.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P1/M05',
    '1': 'B4',
    '2': '05',
    '3': 'P1',
    '4': '56,51',
    '5': 'trzy',
    '6': 'południe, zachód, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P1-M05.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P1/M06',
    '1': 'B4',
    '2': '06',
    '3': 'P1',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P1-M06.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P1/M07',
    '1': 'B4',
    '2': '07',
    '3': 'P1',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P1/M08',
    '1': 'B4',
    '2': '08',
    '3': 'P1',
    '4': '50,50',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P1-M08.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P2/M09',
    '1': 'B4',
    '2': '09',
    '3': 'P2',
    '4': '56,51',
    '5': 'trzy',
    '6': 'południe, zachód, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P2-M09.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P2/M10',
    '1': 'B4',
    '2': '10',
    '3': 'P2',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P2-M10.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P2/M11',
    '1': 'B4',
    '2': '11',
    '3': 'P2',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P2-M11.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P2/M12',
    '1': 'B4',
    '2': '12',
    '3': 'P2',
    '4': '50,50',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P2-M12.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P3/M13',
    '1': 'B4',
    '2': '13',
    '3': 'P3',
    '4': '56,51',
    '5': 'trzy',
    '6': 'południe, zachód, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P3-M13.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P3/M14',
    '1': 'B4',
    '2': '14',
    '3': 'P3',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P3-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P3/M15',
    '1': 'B4',
    '2': '15',
    '3': 'P3',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P3-M15.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B04/P3/M16',
    '1': 'B4',
    '2': '16',
    '3': 'P3',
    '4': '50,50',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'I kwartał 2021',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B04-P3-M16.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },

  {
    '0': 'OJP/B03/P0/M01',
    '1': 'B3',
    '2': '01',
    '3': 'P0',
    '4': '40,57',
    '5': 'dwa',
    '6': 'południe, zachód, wschód',
    '7': 'Gotowe do odbioru',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P0-M01.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P0/M02',
    '1': 'B3',
    '2': '02',
    '3': 'P0',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P0/M03',
    '1': 'B3',
    '2': '03',
    '3': 'P0',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P0/M04',
    '1': 'B3',
    '2': '04',
    '3': 'P0',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P0-M04.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P0/M05',
    '1': 'B3',
    '2': '05',
    '3': 'P0',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P0-M05.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P1/M06',
    '1': 'B3',
    '2': '06',
    '3': 'P1',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P1-M06.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P1/M07',
    '1': 'B3',
    '2': '07',
    '3': 'P1',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P1/M08',
    '1': 'B3',
    '2': '08',
    '3': 'P1',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P1-M08.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P1/M09',
    '1': 'B3',
    '2': '09',
    '3': 'P1',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P1-M09.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P1/M10',
    '1': 'B3',
    '2': '10',
    '3': 'P1',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P1-M10.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P2/M11',
    '1': 'B3',
    '2': '11',
    '3': 'P2',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P2-M11.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P2/M12',
    '1': 'B3',
    '2': '12',
    '3': 'P2',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P2-M12.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P2/M13',
    '1': 'B3',
    '2': '13',
    '3': 'P2',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P2-M13.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P2/M14',
    '1': 'B3',
    '2': '14',
    '3': 'P2',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P2-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P2/M15',
    '1': 'B3',
    '2': '15',
    '3': 'P2',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P2-M15.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P3/M16',
    '1': 'B3',
    '2': '16',
    '3': 'P3',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P3-M16.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P3/M17',
    '1': 'B3',
    '2': '17',
    '3': 'P3',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P3-M17.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P3/M18',
    '1': 'B3',
    '2': '18',
    '3': 'P3',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P3-M18.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P3/M19',
    '1': 'B3',
    '2': '19',
    '3': 'P3',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P3-M19.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B03/P3/M20',
    '1': 'B3',
    '2': '20',
    '3': 'P3',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B03-P3-M20.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P0/M01',
    '1': 'B2',
    '2': '01',
    '3': 'P0',
    '4': '50,44',
    '5': 'trzy',
    '6': 'południe, zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P0-M1.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P0/M02',
    '1': 'B2',
    '2': '02',
    '3': 'P0',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P0-M2.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P0/M03',
    '1': 'B2',
    '2': '03',
    '3': 'P0',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P0-M3.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P0/M04',
    '1': 'B2',
    '2': '04',
    '3': 'P0',
    '4': '50,50',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P0-M4.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P1/M05',
    '1': 'B2',
    '2': '05',
    '3': 'P1',
    '4': '56,51',
    '5': 'trzy',
    '6': 'południe, zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P1-M5.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P1/M06',
    '1': 'B2',
    '2': '06',
    '3': 'P1',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P1-M6.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P1/M07',
    '1': 'B2',
    '2': '07',
    '3': 'P1',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P1-M7.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P1/M08',
    '1': 'B2',
    '2': '08',
    '3': 'P1',
    '4': '50,50',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P1-M8.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P2/M09',
    '1': 'B2',
    '2': '09',
    '3': 'P2',
    '4': '56,51',
    '5': 'trzy',
    '6': 'południe, zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P2-M9.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P2/M10',
    '1': 'B2',
    '2': '10',
    '3': 'P2',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P2-M10.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P2/M11',
    '1': 'B2',
    '2': '11',
    '3': 'P2',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P2-M11.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P2/M12',
    '1': 'B2',
    '2': '12',
    '3': 'P2',
    '4': '50,50',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P2-M12.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P3/M13',
    '1': 'B2',
    '2': '13',
    '3': 'P3',
    '4': '56,51',
    '5': 'trzy',
    '6': 'południe, zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P3-M13.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P3/M14',
    '1': 'B2',
    '2': '14',
    '3': 'P3',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P3-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P3/M15',
    '1': 'B2',
    '2': '15',
    '3': 'P3',
    '4': '43,35',
    '5': 'dwa',
    '6': 'południe, zachód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P3-M15.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B02/P3/M16',
    '1': 'B2',
    '2': '16',
    '3': 'P3',
    '4': '50,50',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B02-P3-M16.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },

  {
    '0': 'OJP/B01/P0/M01',
    '1': 'BONE',
    '2': '01',
    '3': 'P0',
    '4': '40,57',
    '5': 'dwa',
    '6': 'południe, zachód, wschód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P0-M01.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P0/M02',
    '1': 'BONE',
    '2': '02',
    '3': 'P0',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P0-M02.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P0/M03',
    '1': 'BONE',
    '2': '03',
    '3': 'P0',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P0-M03.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P0/M04',
    '1': 'BONE',
    '2': '04',
    '3': 'P0',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P0-M04.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P0/M05',
    '1': 'BONE',
    '2': '05',
    '3': 'P0',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P0-M05.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P1/M06',
    '1': 'BONE',
    '2': '06',
    '3': 'P1',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P1-M06.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P1/M07',
    '1': 'BONE',
    '2': '07',
    '3': 'P1',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P1-M07.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P1/M08',
    '1': 'BONE',
    '2': '08',
    '3': 'P1',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P1-M08.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P1/M09',
    '1': 'BONE',
    '2': '09',
    '3': 'P1',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P1-M09.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P1/M10',
    '1': 'BONE',
    '2': '10',
    '3': 'P1',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P1-M10.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P2/M11',
    '1': 'BONE',
    '2': '11',
    '3': 'P2',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P2-M11.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P2/M12',
    '1': 'BONE',
    '2': '12',
    '3': 'P2',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P2-M12.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P2/M13',
    '1': 'BONE',
    '2': '13',
    '3': 'P2',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P2-M13.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P2/M14',
    '1': 'BONE',
    '2': '14',
    '3': 'P2',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P2-M14.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P2/M15',
    '1': 'BONE',
    '2': '15',
    '3': 'P2',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P2-M15.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P3/M16',
    '1': 'BONE',
    '2': '16',
    '3': 'P3',
    '4': '57,57',
    '5': 'trzy',
    '6': 'południe, zachód, wschód',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P3-M16.pdf',
    '12': 'medium5059',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P3/M17',
    '1': 'BONE',
    '2': '17',
    '3': 'P3',
    '4': '48,34',
    '5': 'dwa',
    '6': 'południe, zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P3-M17.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P3/M18',
    '1': 'BONE',
    '2': '18',
    '3': 'P3',
    '4': '40,41',
    '5': 'dwa',
    '6': 'zachód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P3-M18.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P3/M19',
    '1': 'BONE',
    '2': '19',
    '3': 'P3',
    '4': '47,24',
    '5': 'dwa',
    '6': 'zachód, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P3-M19.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },
  {
    '0': 'OJP/B01/P3/M20',
    '1': 'BONE',
    '2': '20',
    '3': 'P3',
    '4': '47,34',
    '5': 'dwa',
    '6': 'południe, wschód, północ',
    '7': 'III kwartał 2020',
    '8': 'Sprawdź',
    '9': 'Zapytaj',
    '10': 'Zobacz',
    '11': 'https://willbud.pl/_pliki/OJP-B01-P3-M20.pdf',
    '12': 'small4049',
    '13': 'true',
    '14': 'https://willbud.pl/new/janowologo.png',
    '15': "janowo"
  },

]
