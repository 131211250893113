import React, { Fragment } from "react"
import EstateStatus from "../estateStatus"
import EstateTitle from "../estateTitle"
import EstateNav from "../janowotwo/estateNav"
import { HeaderContainer, PlanVisual } from "./wiczlinoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"

import logo from "@/images/z-20/logo_ogw2.png"

const WiczlinoHeader = ({ scrollDestination, intl }) => {
  const noPadding = { margin: "auto" }
  const desc = intl.formatMessage({
    id: "offer.wiczlinotwo.content",
  })
  return (
    <Fragment>
      <HeaderContainer>
        <Container style={noPadding}>
          <Row>
            <Col
              className="content-wrapper"
              style={noPadding}
              xs="12"
              sm="12"
              md="6"
              lg="4"
            >
              <EstateTitle
                subTitleBold={intl.formatMessage({
                  id: "offer.wiczlinotwo.content.bold",
                })}
                subtitle={desc}
              />
            </Col>
            <Col
              xs="12"
              sm="12"
              md="6"
              lg="4"
              className={"justify-content-center align-items-center d-flex"}
            >
              <img
                src={logo}
                alt={"Osiedle OGW2"}
                style={{ maxWidth: "70%", height: "auto" }}
              />
            </Col>
            <Col className="header-nav" xs="0" sm="0" md="6" lg="4">
              <EstateNav scrollDestination={scrollDestination} />
            </Col>
            <Col className="desktop-info" xs="12">
              <div>
                23{" "}
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.wiczlinotwo.info.buildings.name",
                  })}
                </span>
              </div>
              <div>
                46{" "}
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.wiczlinotwo.info.flats.name",
                  })}
                </span>
              </div>
              <div>
                139 - 160,5m<sup>2</sup>
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.wiczlinotwo.info.space.name",
                  })}
                </span>
              </div>
              <div>
                400 - 500m<sup>2</sup>
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.wiczlinotwo.info.sold.name",
                  })}
                </span>
              </div>
              <div></div>
            </Col>
          </Row>
        </Container>
      </HeaderContainer>
    </Fragment>
  )
}

export default injectIntl(WiczlinoHeader)
