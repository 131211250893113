export const wiczlino = [
    {
        '0': 'OGW/M1.01',
        '1': '1',
        '2': '01',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '572,10',
        '6': 'południe, zachód, wschód',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/1.01.pdf',
        '12': 'large',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M1.02',
        '1': '1',
        '3': '134,40m2',
        '2': '02',
        '5': 'Zapytaj',
        '4': '416,00',
        '6': 'południe, zachód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/1.02.pdf',
        '12': 'small',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M2.01',
        '1': '1',
        '2': '03',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '476,00',
        '6': 'zachód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/2.01.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M2.02',
        '1': '1',
        '2': '04',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '436,00',
        '6': 'zachód, wschód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/2.02.pdf',
        '12': 'small',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M3.01',
        '1': '1',
        '2': '05',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '490,65',
        '6': 'południe, wschód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/3.01.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M3.02',
        '1': '1',
        '2': '06',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '507,95',
        '6': 'południe, zachód, wschód',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/3.02.pdf',
        '12': 'large',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M4.01',
        '1': '1',
        '2': '07',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '485,10',
        '6': 'południe, zachód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/4.01.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M4.02',
        '1': '1',
        '2': '08',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '477,15',
        '6': 'zachód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/4.02.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M5.01',
        '1': '1',
        '2': '09',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '445,32',
        '6': 'zachód, wschód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/5.01.pdf',
        '12': 'small',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M5.02',
        '1': '1',
        '2': '10',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '477,47',
        '6': 'południe, wschód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/5.02.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M6.01',
        '1': '2',
        '2': '11',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '554,90',
        '6': 'południe, zachód, wschód',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/6.01.pdf',
        '12': 'large',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M6.02',
        '1': '2',
        '2': '12',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '459,00',
        '6': 'południe, zachód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/6.02.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M7.01',
        '1': '2',
        '2': '13',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '493,00',
        '6': 'zachód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/7.01.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M7.02',
        '1': '2',
        '2': '14',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '540,00',
        '6': 'zachód, wschód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/7.02.pdf',
        '12': 'large',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M8.01',
        '1': '2',
        '2': '15',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '471,75',
        '6': 'południe, wschód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/8.01.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M8.02',
        '1': '2',
        '2': '16',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '495,65',
        '6': 'południe, zachód, wschód',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/8.02.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M9.01',
        '1': '2',
        '2': '17',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '402,00',
        '6': 'południe, zachód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/9.01.pdf',
        '12': 'small',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M9.02',
        '1': '2',
        '2': '18',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '463,77',
        '6': 'zachód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/9.02.pdf',
        '12': 'medium',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M10.01',
        '1': '2',
        '2': '19',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '409,87',
        '6': 'zachód, wschód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/10.01.pdf',
        '12': 'small',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
      {
        '0': 'OGW/M10.02',
        '1': '2',
        '2': '20',
        '3': '134,40m2',
        '5': 'Zapytaj',
        '4': '628,10',
        '6': 'południe, wschód, północ',
        '7': 'Oddane do odbioru',
        '8': 'Sprawdź',
        '9': 'Zapytaj',
        '10': 'Zobacz',
        '11': 'https://willbud.pl/_pliki/10.02.pdf',
        '12': 'large',
        '13': 'true',
        '14': 'https://willbud.pl/new/logowiczlino.png',
        '15': 'wiczlino'
      },
]